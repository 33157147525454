:root {
  --color-black: #242424;

  --color-signal-red: #ff3e55;
  --color-signal-yellow: #ffb526;
  --color-signal-green: #10e4a4;

  --color-text-default: #ffffff;
  --color-text-highlight: #000000;

  --color-grey-300: #fafafa;
  --color-grey-400: #f0f0f0;
  --color-grey-600: #adadad;
  --color-grey-650: #868686;
  --color-grey-700: #666666;
  --color-grey-800: #373737;
  --color-grey-900: #1a1a1a;
  --color-grey-950: #111111;
  --color-black-900: #000000;

  --color-primary-900: #1a0f1c;
  --color-primary-800: #7e19ff;
  --color-primary-750: #9747ff;
  --color-primary-700: #c4a5ec;
  --color-primary-rgba: rgba(126, 25, 255, 0.5);

  --color-rarity-common: #ffffff;
  --color-rarity-uncommon: #52cb21;
  --color-rarity-rare: #3282ff;
  --color-rarity-epic: #7e19ff;
  --color-rarity-legendary: #ff8819;

  --box-shadow-rarity-common: 0px 0px 18px 0px rgba(255, 255, 255, 0.65), 0px 0px 9.3px 0px rgba(15, 38, 5, 0.33),
    3px 3px 3px 0px rgba(255, 255, 255, 0.4) inset, -3px -3px 3px 0px rgba(0, 0, 0, 0.4) inset;
  --box-shadow-rarity-uncommon: 0px 0px 18px 0px rgba(72, 179, 29, 0.65), 0px 0px 9.3px 0px rgba(72, 179, 29, 0.33),
    3px 3px 3px 0px rgba(255, 255, 255, 0.4) inset, -3px -3px 3px 0px rgba(0, 0, 0, 0.4) inset;
  --box-shadow-rarity-rare: 0px 0px 18px 0px rgba(42, 112, 221, 0.65), 0px 0px 9.3px 0px rgba(42, 112, 221, 0.33),
    3px 3px 3px 0px rgba(255, 255, 255, 0.4) inset, -3px -3px 3px 0px rgba(0, 0, 0, 0.4) inset;
  --box-shadow-rarity-epic: 0px 0px 18px 0px rgba(158, 42, 250, 0.65), 0px 0px 9.3px 0px rgba(158, 42, 250, 0.33),
    3px 3px 3px 0px rgba(255, 255, 255, 0.4) inset, -3px -3px 3px 0px rgba(0, 0, 0, 0.4) inset;
  --box-shadow-rarity-legendary: 0px 0px 18px 0px rgba(250, 129, 42, 0.65), 0px 0px 9.3px 0px rgba(250, 129, 42, 0.33),
    3px 3px 3px 0px rgba(255, 255, 255, 0.4) inset, -3px -3px 3px 0px rgba(0, 0, 0, 0.4) inset;

  --gradient-rarity-common: linear-gradient(128deg, #ebeaea 10.5%, #1b1b1b 52.84%, #ebeaea 82.44%);
  --gradient-rarity-uncommon: linear-gradient(128deg, #52cb21 10.5%, #091703 52.84%, #52cb21 82.44%);
  --gradient-rarity-rare: linear-gradient(128deg, #3282ff 10.5%, #071325 52.84%, #3282ff 82.44%);
  --gradient-rarity-epic: linear-gradient(128deg, #7e19ff 10.5%, #0d0319 52.84%, #7e19ff 82.44%);
  --gradient-rarity-legendary: linear-gradient(128deg, #ff8819 10.5%, #201204 52.84%, #ff8819 82.44%);
}
