:root {
  --font-size-headline-xs: 20px;
  --font-size-headline-sm: 24px;
  --font-size-headline-md: 28px;
  --font-size-headline-lg: 36px;
  --font-size-headline-xl: 44px;
  --font-size-headline-xxl: 56px;

  @media (min-width: 768px) {
    --font-size-headline-md: 32px;
    --font-size-headline-lg: 48px;
    --font-size-headline-xl: 60px;
    --font-size-headline-xxl: 68px;
  }

  --font-size-body-xs: 12px;
  --font-size-body-sm: 14px;
  --font-size-body-md: 16px;
  --font-size-body-lg: 18px;
}
